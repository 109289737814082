import React from "react";
import Layout from "components/layout";
import SummaryTool from "components/SummaryTool";
import SEO from "components/seo";
import ToolFaq from "components/QualityTools/ToolFaq";
import { TextSummariseToolFaqData } from "components/FAQ/config";
import BannerSection from "components/PostTranslation/BannerSection";
import BannerImage from "assets/textsummarizer_banner.png"

function index() {
  const title = "AI Text Summarizer - Free Summary Generator";
  const description =
    " Instantly summarize articles, PDFs, and web pages with the free Tomedes AI Text Summarizer. No word limits, no sign up. Ideal for translators, marketers, students, and writers.";
  const keywords =
    "summary, summarizer, summarizing, summarizing tool, text summary, article summary, document summary";

    const BannerData = [
      {
        text: "<span class='font-bold'>Summarize long texts instantly</span> - Create concise summaries from lengthy documents in seconds.",
      },
      {
        text: "<span class='font-bold'>Enjoy unlimited free usage </span> - No word limits, no hidden fees, and no sign up required.",
      },
      {
        text: "<span class='font-bold'>Work with URLs, PDFs, images, and files </span> - Summarize content from links, documents, or extracted text from images.",
      },
      {
        text: "<span class='font-bold'>Support multilingual projects </span>  - Shorten texts in any language, and get accurate summaries in the same language as their source.",
      },
      {
        text: "<span class='font-bold'>Rely on AI-powered, expert-driven technology </span> - Built with advanced AI and Tomedes' linguistic expertise.",
      },
      
    ];

  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/tools/text-summarizer"
      />
      <SummaryTool />
      <div className="bg-white ">
          <div className="max-w-7xl mx-auto p-4">
            <BannerSection
              title="Summarize Long Text, PDF, or URL for Free"
              description="Whether you're condensing articles, summarizing research papers, creating executive summaries for business reports, or shortening long study materials, the Tomedes AI Text Summarizer helps you instantly generate clear, actionable summaries.
              <br /><br/>
              Automatically shorten text from long documents, websites, and even extract and summarize text from images - all in just one click.<br/><br/>
              With no word limits and free access, the Tomedes AI Text Summarizer is the perfect tool for students, researchers, marketers, and professionals who need instant and accurate summaries whenever they need them.
              "

              BannerData={BannerData}
              BannerImage={BannerImage}
              text="Take advantage of this free AI summarizer to make your reading and analysis faster, sharper, and smarter."
             isTranslatoir={true}
           />
          </div>
        </div>
      <ToolFaq QaToolFaqData={TextSummariseToolFaqData} />
    </Layout>
  );
}

export default index;
