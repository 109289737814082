import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSummaryTool } from "hooks/Forms/useSummaryTool";
import { useLocation } from "@reach/router";
import Loader from "components/loader";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import { Link, navigate } from "gatsby";
import http from "utils/http";
import { endpoints } from "utils/endpoints";

import UploadIcon from "assets/uploadIcon.svg";
import { fileExtension } from "utils/fileextension";
import ReUploadFile from "components/FileUploading/reUploadingfile";
import UnsupportedFile from "components/FileUploading/unsupportedfile";
import FileLoadingInput from "components/FileUploading/fileloadinginput";
import CleanButton from "assets/cleanBtn.svg";
import Layout from "components/layout";
import useDebounce from "components/QualityTools/useDebounce";
import { direction } from "utils/direction";
import detectLanguage from "utils/detectLanguage";
import { trySampleText } from "utils/sampletext-generator";
import useAutoFocus from "hooks/useAutoFocus";
import SummaryOutput from "./SummaryOutput";

function FirstSummaryTool() {
  const { status, mutateAsync } = useSummaryTool();
  const location = useLocation();
  const [toggleTool, setToggleTool] = useState(false);
  const [summarizedText, setSummarizedText] = useState("");
  const [isEnterText, setIsEnterText] = useState(true);
  const [wordCount, setWordCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [urlText, setUrlText] = useState("");
  const [hasSummary, setHasSummary] = useState(false);
  const [shareId, setShareId] = useState("");
  const [error, setError] = useState(false);
  const [fileUploadLoading, setFileUploading] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [file, setFile] = useState(false);
  const [sizeLimitError, setSizeLImitError] = useState(false);
  const [inputLang, setInputLang] = useState("");
  const debouncedInputText = useDebounce(messageText, 3000);
  const [textLoader, setTextLoader] = useState(false);
  const [copySuccess, setCopySuccess] = useState("Copy");
  // const [tooltipContent, setTooltipContent] = useState("Copy");
  const summarizedTextRef = useRef(null);

  const url = location.href;

  useAutoFocus("textarea");
  useEffect(() => {
    if (debouncedInputText?.trim()?.length >= 3) {
      detectLanguageForSource(debouncedInputText);
    }
  }, [debouncedInputText]);
  const detectLanguageForSource = async (text) => {
    const { languageCode } = await detectLanguage(text);
    if (languageCode) {
      setInputLang(languageCode);
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = async () => {
    setLoading(true);
    let payload;
    if (urlText){
      payload = {
        tool_name: "summarizer",
        user_url: urlText,
      };
    } else {
      payload = {
        tool_name: "summarizer",
        user_text: messageText,
        source_language_code: inputLang,
      };
    }
 
    try {
      const response = await http().post(endpoints.forms.aiTools, payload);
  
      const newShareId = response?.meta?.share_id;
      setShareId(newShareId);
      setToggleTool(true);
  
      if (newShareId === undefined || !response.success) {
        setError("Unable to generate a response. Please try again.");
        return;
      }
      if (Array.isArray(response.result)) {
        setSummarizedText(
          response.result.map((item) => item.summarized_text).join("\n")
        );
      } else if (typeof response.result === "object") {
        setSummarizedText(response.result.summarized_text);
      }

      setToggleTool(true);
      reset();
      window.scrollTo(0, 0);
   
      // navigate(`/tools/text-summarizer/result?share-id=${newShareId}`);
    } catch (error) {
      setError(error?.message?.user_url)

    } finally {
      setLoading(false);
    }
  };

  const handleTextChange = (e) => {
    const text = e.target.value.trim();
    setMessageText(text);
    const words = text.split(/\s+/).filter((word) => word.length > 0);
    setWordCount(words.length);
    setHasSummary(false);
  };
  

  const clearText = () => {
    setWordCount(0);
    setMessageText("");
    reset({ message: "" });
    setSummarizedText("");
    setHasSummary(false);
    setInputLang("");
  };

  const handleLinkChange = (e) => {
    const text = e.target.value;
    setUrlText(text);
    setHasSummary(false);
  };

  const clearLink = () => {
    setUrlText("");
    reset({ url: "" });
    setSummarizedText("");
    setHasSummary(false);
  };
  const copyText = () => {
    if (summarizedTextRef.current) {
      const textToCopy = summarizedTextRef.current.value;

      navigator.clipboard.writeText(textToCopy);
      setCopySuccess("Copied");
      setTimeout(() => {
        setCopySuccess("Copy");
      }, 1500);
    }
  };

  // file Upload

  const uploadFile = async (e) => {
    setSizeLImitError(false);
    setUploadError(false);
    const selectedFile = e.target.files[0];
    const extension = fileExtension(selectedFile.name);

    if (!extension) {
      setUploadError(true);
      setFile(selectedFile);
      return;
    }
    setFile(selectedFile);
    if (selectedFile.size >= 15000000) {
      alert("File is too large. Please upload file less than 10 MB.");
      setSizeLImitError(true);
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      setFileUploading(true);
      setUploadError(null);
      const res = await http().post(endpoints.forms.toolsFileUpload, formData);
      setMessageText(res.data.text);
      setValue("message", res.data.text);
    } catch (err) {
      console.log(err, "error");
      setUploadError("Error uploading file. Please try again.");
    } finally {
      setFileUploading(false);
    }
  };

  const toggleInput = (isTextInput) => {
    setIsEnterText(isTextInput);
    setUrlText("");
    setMessageText("")
    setSummarizedText("")
    setHasSummary(false);
  };

    const shareresult = () => {
      navigator.clipboard.writeText(`${url}/result?share-id=${shareId}`);
      setCopySuccess("Link copied");
  
      setTimeout(() => {
        setCopySuccess("Copy");
      }, 2000);
    };

  return (
    <div className="bg-[#F5F5F5] ">
      <div className=" w-full py-7 ">
        <ToolsBreadcrumb />
      </div>

      <div className="pb-[48px]">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 mt-3">
          <h1 className="text-[#5B93FF] font-semibold text-base font-primary text-center ">
          Free AI Text Summarizer
          </h1>
          {/* <h2 className="mt-3 text-[#0A2641] font-primary text-[28px] lg:text-[64px] font-bold leading-[48px] lg:leading-[77px] text-center ">
            Summarize your long text for free
          </h2> */}
          <div className="grid lg:grid-cols-2 gap-3 mt-10">
            <div className="bg-[#ffffff] rounded-lg shadow-lg p-5">
              <div className="flex gap-3">
                <button
                  onClick={() => toggleInput(true)}
                  className={`py-1 px-3 rounded-md font-semibold ${
                    isEnterText
                      ? "text-white bg-[#7AA7FF]"
                      : "text-[#7AA7FF] bg-[#F0F5FF]"
                  }`}
                >
                  Enter Text
                </button>
                <button
                  onClick={() => toggleInput(false)}
                  className={`py-1 px-3 rounded-md font-semibold ${
                    isEnterText
                      ? "text-[#7AA7FF] bg-[#F0F5FF]"
                      : "text-white bg-[#7AA7FF]"
                  }`}
                >
                  Enter URL
                </button>
              </div>
              <div className="">
              {isEnterText ? (
                <div>
                  {fileUploadLoading ? (
                    <FileLoadingInput file={file} />
                  ) : sizeLimitError && !fileUploadLoading ? (
                    <ReUploadFile
                      file={file}
                      setFile={setFile}
                      setSizeLImitError={setSizeLImitError}
                      uploadFile={uploadFile}
                    />
                  ) : uploadError && !fileUploadLoading ? (
                    <UnsupportedFile
                      file={file}
                      setSizeLImitError={setSizeLImitError}
                      setFile={setFile}
                      uploadFile={uploadFile}
                      setFileUploadError={setUploadError}
                    />
                  ) : (
                    <div className="flex items-start  mt-2 gap-4 relative">
                      <textarea
                        autoFocus
                        dir={direction(inputLang)}
                        name="message"
                        id="message"
                
                        value={messageText}
                        className={`anonymize_tool   min-h-[280px] font-opensans placeholder-[#858585] text-black font-normal leading-[26px] pb-3  ${
                          direction(inputLang) === "rtl"
                            ? "pl-[40px]"
                            : "pr-[40px]"
                        } bg-transparent text-lg overscroll-y-none resize-none border-none outline-none block w-full`}
                        placeholder={textLoader ? "" : "Enter text here or"}
                        onChange={handleTextChange}
                      />

                      {textLoader && (
                        <div className="absolute top-0 left-[14px] text-[#B4B4B4] italic">
                          Adding sample text...
                        </div>
                      )}
                      {!messageText && !textLoader && (
                        <button
                          className={`absolute -top-[2px] left-[150px] underline italic text-[#5B93FF] cursor-pointer font-normal text-lg pointer-events-auto`}
                          onClick={() =>
                            trySampleText(setMessageText, setTextLoader, "tryToolTextAnonymizeTool")
                          }
                        >
                          Try Sample Text
                        </button>
                      )}

                      {messageText && (
                        <img
                          src={CleanButton}
                          alt="clean-button"
                          onClick={clearText}
                          className={`cursor-pointer ${
                            direction(inputLang) === "rtl"
                              ? "left-[14px]"
                              : "right-[14px]"
                          }  absolute `}
                        />
                      )}
                    </div>
                  )}
                  <div className="flex justify-between items-center  mt-8">
                    {messageText ? (
                      <p className="text-right mt-2 text-sm text-[#9C9C9C] font-semibold font-opensans">
                        {messageText?.split(/\s+/).filter((word) => word?.length > 0)?.length} words
                      </p>
                    ) : (
                      <>
                        <label
                          htmlFor="uploadFile"
                          className="flex gap-2 cursor-pointer"
                        >
                          <img src={UploadIcon} alt="upload_icon" />
                          <span>Upload File</span>
                        </label>
                        <input
                          type="file"
                          className="hidden"
                          id="uploadFile"
                          onChange={uploadFile}
                        />
                      </>
                    )}
                    <div className="flex gap-2">

                      <button
                        disabled={summarizedText || !messageText || !inputLang}
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                        className={` ${
                          summarizedText
                            ? "bg-[#E8E8E8] text-[#858585] cursor-not-allowed"
                            : "bg-[#5B93FF] text-white "
                        } text-white px-4 py-2 rounded-md`}
                      >
                        {loading ? <Loader /> : "Summarize text"}
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                  <div>
                    <textarea
                      
                      name="url"
                      value={urlText}
                      placeholder="Paste URL here"
                      className={`min-h-[250px]  font-opensans placeholder-[#858585] text-[#1463FF] leading-[26px] py-3 px-3 bg-transparent text-lg overscroll-y-none resize-none border-none outline-none block w-full`}
                      onChange={handleLinkChange}
                      readOnly={loading}
                    />
                    <div className="flex justify-between items-center p-4 mt-8">
                      <span></span>
                      <div className="flex gap-2">
                        <button
                          type="button"
                          className={` ${
                            urlText.length > 0
                              ? "text-[#0A2641] border border-[#0A2641] cursor-pointer"
                              : "border border-[#B0B0B0] text-[#B0B0B0]"
                          } flex gap-2 bg-white px-4 py-2 rounded-md`}
                          onClick={clearLink}
                          disabled={urlText.length === 0}
                        >
                          Clear link
                        </button>
                        <button
                          type="button"
                          onClick={handleSubmit(onSubmit)}
                          disabled={!urlText}
                          className={`bg-[#5B93FF] ${
                            !urlText ? " cursor-not-allowed" : ""
                          } text-white px-4 py-2 rounded-md`}
                        >
                          {loading ? <Loader /> : "Summarize text"}
                        </button>
                      </div>
                    </div>
                  </div>
                )} 
              </div>
            </div>
          
            <SummaryOutput 
            inputLang={inputLang}     
            summarizedTextRef={summarizedTextRef}
            copyText={copyText}
            copySuccess={copySuccess}
            summarizedText={summarizedText}
            shareresult={shareresult}
            />
          </div>
        </div>
        <p className="w-full text-center text-base mt-12 pb-4 text-[#9C9C9C]">
          Assisted by AI tools. Inaccuracies may occur.
        </p>
        {/* <p className="mt-8 md:mt-12  px-4 sm:px-6 font-opensans max-w-3xl mx-auto text-[#424242] text-lg font-normal text-center">
          Our text summarizer helps you quickly generate concise summaries from
          articles, PDFs, and websites. Just paste your content or a URL, and
          get a summary instantly.
        </p> */}
      </div>
    </div>
  );
}

export default FirstSummaryTool;
