import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import { direction } from "utils/direction";
import DownloadSection from "utils/resultDownload";
import CopyIcon from "assets/copytools.jpg";
import * as XLSX from "xlsx";
import CopyLink from "assets/copu_link_icon.svg";

const SummaryOutput = ({
  summarizedText,
  error,
  summarizedTextRef,
  copyText,
  inputLang,
  copySuccess,
  shareresult,
}) => {
  const [selectedFormat, setSelectedFormat] = useState("Download CSV");
  const downloadOutputFormat = ["Download CSV", "Download XLS"];

  const handleFormatChange = (format) => {
    setSelectedFormat(format);
  };

  const handleDownload = (format) => {
    if (format === "Download CSV") {
      downloadCSV();
    } else if (format === "Download XLS") {
      downloadXLS();
    }
  };
  const downloadXLS = () => {
    if (!summarizedText) return;

    const csvData = [{ "Summarized Text": summarizedText.replace(/"/g, '""') }];
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "summarized_text in XLSX");

    XLSX.writeFile(wb, "summarized_text.xlsx");
  };

  const downloadCSV = () => {
    if (!summarizedText) return;

    const csvData = `Summarized Text\n"${summarizedText.replace(/"/g, '""')}"`;
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "summarized_text.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div
      style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
      className="bg-[#ffffff] relative  rounded-lg shadow-lg p-5"
    >
      <div className="flex justify-between gap-1">
        <p className="text-base text-[#B0B0B0] font-bold leading-6 font-opensans">
          Summarized text
        </p>
      </div>

      <div className="mt-4">
        <textarea
          dir={direction(inputLang)}
          value={summarizedText || error?.message || ""}
          ref={summarizedTextRef}
          className={`tool min-h-[280px] border-none outline-none font-opensans placeholder-[#858585] ${
            error?.message ? "text-[#ff0000]" : "text-[#6E6E6E]"
          }  leading-[26px]  bg-transparent text-sm overscroll-y-none resize-none  block w-full`}
          readOnly
        />
      </div>
      <div className="mt-5">
        <div className=" px-4 absolute bottom-0 left-0 py-6 w-full flex flex-wrap items-center justify-end ">
          <div className="flex justify-between w-full">
            <p className="text-[#9C9C9C] font-semibold font-opensans text-sm">
              {
                summarizedText?.split(/\s+/)?.filter((word) => word?.length > 0)
                  ?.length
              }{" "}
              words
            </p>
            {summarizedText && (
              <div className="flex gap-4 ">
                <button
                  data-tooltip-id="copy-tooltip"
                  data-tooltip-content={copySuccess}
                  onClick={copyText}
                >
                  <img src={CopyIcon} alt="/copyicons" />
                </button>
                {shareresult && (
                  <button
                    className="flex items-center gap-2 w-max"
                    data-tooltip-id="linkcopy-tooltip"
                    data-tooltip-content={copySuccess}
                    onClick={shareresult}
                  >
                    <img src={CopyLink} alt="copy_link" />
                  </button>
                )}
                <DownloadSection
                  handleDownload={handleDownload}
                  selectedFormat={selectedFormat}
                  handleFormatChange={handleFormatChange}
                  downloadOutputFormat={downloadOutputFormat}
                />
              </div>
            )}
          </div>
          <Tooltip
            id="copy-tooltip"
            place="top"
            multiline={true}
            className="z-50"
          />
          <Tooltip
            id="linkcopy-tooltip"
            place="top"
            multiline={true}
            className="z-50"
          />
        </div>
      </div>
    </div>
  );
};

export default SummaryOutput;
